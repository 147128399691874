module.exports = [{
      plugin: require('../node_modules/gatsby-theme-carbon/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-carbon/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1152,"linkImagesToOriginal":false,"quality":75,"withWebp":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-theme-carbon/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Carbon Design Gatsby Theme","short_name":"Gatsby Theme Carbon","start_url":"/","background_color":"#161616","theme_color":"#161616","display":"browser","icon":"/home/runner/work/eng618.github.io/eng618.github.io/src/images/icon-v2.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d3c095541d775713d7b7c87cdd419caa"},
    },{
      plugin: require('../node_modules/gatsby-theme-carbon/gatsby-browser.js'),
      options: {"plugins":[],"iconPath":"./src/images/icon-v2.png","titleType":"prepend","shortName":"ENG618","withWebp":true,"repository":{"baseUrl":"https://github.com/eng618/engarcia.github.io","branch":"develop"},"isSwitcherEnabled":false,"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"downloads","ignoreFileExtensions":["png","jpg","jpeg","bmp","tiff","md"]}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eric N. Garcia","short_name":"ENG618","start_url":"/","background_color":"#505050","theme_color":"#9C8BFF","display":"standalone","cache_busting_mode":"none","icon":"./src/images/icon-v2.png","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
